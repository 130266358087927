import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/paratriennale/paratriennale/loveletter/src/templates/works-page-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const FixedRadio = makeShortcode("FixedRadio");
const Caption = makeShortcode("Caption");
const BpHr = makeShortcode("BpHr");
const LinkButton = makeShortcode("LinkButton");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`磯子区障害者地域活動ホーム + 飯塚聡`}</h1>
    <p>{`飯塚聡さんによる約 12 分の映像作品では、リさんの《画板 100 x 400》に対して磯子区障害者地域活動ホームのみなさんが応答する様子や一見無関係にも見える反応が映されています。濁流に画板をかざして立ち向かうリさんの様子を見て、「なにこれ？」と素朴な疑問をぶつける人、一緒に手をかざしてみる人、困惑する人...。作品と同じ空間・時間を共有すること、作品から何かを受け取ることについての問いを投げかけています。`}</p>
    <FixedRadio mdxType="FixedRadio">
  <iframe width="560" height="315" src="https://www.youtube.com/embed/56ixQaT5MPo" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </FixedRadio>
    <Caption before={`作品`} medium={`映像　シングルチャンネル　12分00秒、　2020`} mdxType="Caption">
  《響きとこだま》
    </Caption>
    <BpHr type={`dot`} mdxType="BpHr" />
    <LinkButton to={`/works/binyuan-li_drawingboard`} mdxType="LinkButton">
  作品: リ・ビンユアン
    </LinkButton>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      